// import React, { Component } from 'react'

// export class App extends Component {
//   render() {
//     return (

//       <div>

//       <nav style={{backgroundColor:'#075E54'}} class="navbar navbar-expand-lg  sticky-top p-0 px-4 px-lg-5">
//         <a href="#first" class="navbar-brand d-flex align-items-center">
// 		<img src="stuff.png" alt="timestrail" class="logo" style={{
// marginLeft: 10,
// height: 49,
// width: 49,
// marginRight:10,


//     }} />
//             <h2 style ={{
//               color:'#FFFFFF',
//               marginTop:10
//             }} class=""> Times Trail</h2>
//         </a>
//         <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
//          <i class="fa fa-bars" style={{
//           fontSize:40,
//           color:'#FFFFFF'
//          }}></i>
//         </button>
//         <div class="collapse navbar-collapse" id="navbarCollapse">
//             <div class="navbar-nav ms-auto py-4 py-lg-0" id="myDIV">
//                 <a href="#first" class="nav-item nav-link ">About</a>
//                 <a href="#second" class="nav-item nav-link">Why to use?</a>
//                 <a href="#third" class="nav-item nav-link">How to use?</a>
//                 <a href="#fourth"  class="nav-item nav-link"  >Legal</a>
                
              
//             </div>

//         </div>
//     </nav>

//     <div
//     style={{height:11500,
//     backgroundColor:'#000'}}
//     >

//     </div>

//     </div>

//     )
//   }
// }

// export default App





import React, { useEffect, useState } from "react";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.css';
import "@fontsource/work-sans"; // Defaults to weight 400
import "@fontsource/work-sans/400.css"; // Specify weight
import "@fontsource/work-sans/400-italic.css"; // Specify weight and style
import "./App.css";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Navigation from "./Navigation";
import { Grid, Row, Col } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { initializeApp } from "firebase/app";

const App = () => {
  const [scrolled, setScrolled] = useState(false);

 // Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDyR_rhtgOdJAgnXaGUmmJ1RG-nSg264wo",
  authDomain: "etoile-liaison.firebaseapp.com",
  projectId: "etoile-liaison",
  storageBucket: "etoile-liaison.appspot.com",
  messagingSenderId: "652118775063",
  appId: "1:652118775063:web:34f712b348f2587439d352"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
function Submit(e) {
  const formEle = document.querySelector("form")
  console.log('Submitted');
  const formData = new FormData(formEle);
  fetch("https://script.google.com/macros/s/AKfycbwoUVkQ3v5vkOI5Qmuh0rBikNrhVzrSkiNA6IPVvXzwEIrM6SrIRg3Rxh00uSo4C60g/exec",
  {method:"POST",
  body:formData
}
  ).then(()=>{

  })

}


  return (
    <div
   
    >
       <Navigation/>
       
        <div
        style={{ display:'flex',
        justifyContent:'center',
        alignContent:'center'}}
        >

      <img id="home"
      style={{
          backgroundImage:'url("./homeBg.png")',
        backgroundSize:'initial',
        backgroundRepeat:'no-repeat',
        width:'90%',
        marginBottom:'5%',
        minHeight:200,

      }}

      src="/homeBg.png"
      />


</div>



        {/* <div
        
        style={{
          color:  '#292E3D',
          textAlign: 'center',
          fontFamily: 'Work Sans',
          fontSize: 36,
          fontWeight: '700',
          marginTop:60,
          marginBottom:60,
        }}
        >
        Our Reputation
        </div>
        

        <div

        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'space-evenly',
          marginBottom:84
        }}
          >

<img
        src="/bestService.png"
        style={{
          height:159,
          width:292
        }}
        />
        
<img
        src="/bestTeam.png"
        style={{
          height:159,
          width:292
        }}
        />
        <img
        src="/bestDesign.png"
        style={{
          height:159,
          width:292
        }}
        />
        </div> */}



    <div id="about"
    style={{

      margin: 'auto',
      backgroundColor:'#F6F8F7',
      height:'50%',
      justifyContent:'center',
      display:'flex',
      alignItems:'center',
      flexDirection:'column',
      marginBottom:'5%',
      
      

    }}
    >
  
      <h1
      style={{

        fontFamily: 'Work Sans',
      width: '60%',
        
        fontStyle: 'normal',
        fontWeight: '700',
        textAlign:'center',
        marginTop:'5%',
/* Heading/4 */
      }}
      
      >About us</h1>
      <h5
      style={{
        width: '90%',

fontFamily: 'Work Sans',



fontStyle: 'normal',
fontWeight: '400',
padding:30 
      }}
      
      >
        
        We specialize in licensing assistance for Event permissions
       , food & beverages and Architect liaisoning businesses.
        Our team is utterly dynamic and consists of result oriented professionals,
         who are committed to delivering effective solutions following all legal norms For us.
          The satisfaction of our clients is of uttermost
       importance, With our expertise we help you combat all hurdles & assure you quality & prompt service.
       
       
       
       </h5>
    </div>
   
<div  id="service"
 style={{
  backgroundColor:'#F6F8F7',
  paddingTop:40,
  paddingBottom:40
  }}
>
   <div
 style={{

 }}
   >
    <h1
    style={{
      color:  '#292E3D',
textAlign: 'center',
fontFamily: 'Work Sans',
fontWeight: '700',
paddingBottom:30 

    }}
    >
   Services

    </h1>
   </div>

{/* <div

style={{
  display:'flex',
  justifyContent:'center'
}}
>
<img
src="/FBHospital.png"
style={{
  height:159,
  width:292
}}
/>
</div>

   <div

style={{
  flexDirection:'row',
  display:'flex',
  justifyContent:'space-evenly',
  marginBottom:60,

}}
  >

<img
src="/Service.png"
style={{
  height:159,
  width:292
}}
/>

<img
src="/OtherPermission.png"
style={{
  height:159,
  width:292
}}
/>
<img
src="/Event.png"
style={{
  height:159,
  width:292
}}
/>
</div> */}




<Grid>
                <Row>
                    <Col  xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                         
style={{
  display: 'flex',
width: '20%',

padding: '3%',

flexDirection: 'column',
justifyContent: 'center',
alignItems: 'center',
borderRadius:4,
backgroundColor:'#256A01',
marginRight:'5%',

}}
                         >
                        
                      <p

style={{
  color:  '#FFF',
textAlign: 'center',
fontFamily: 'Work Sans',
fontSize:'70%',
fontWeight: '600',

}}
>
  
  
F and B Hospitality  </p>

                    </Col>
                    <Col  xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                         
                         style={{
                          display: 'flex',
                        width: '20%',
                        minHeight: '30%',
                        
                        padding: '3%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius:4,
                        backgroundColor:'#256A01',
                        marginRight:'5%',
                        
                        }}
                         >
                           
                       <p

style={{
  color:  '#FFF',
textAlign: 'center',
fontFamily: 'Work Sans',
fontSize:'70%',

fontWeight: '600',
}}
>
  
  
Services </p>
                    </Col>
                    <Col  xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                         
                         
                         style={{
                          display: 'flex',
                        width: '20%',
                        minHeight: '30%',
                        
                        padding: '3%',

                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius:4,
                        backgroundColor:'#256A01',
                        marginRight:'5%',
                        
                        }}>
                     <p

style={{
  color:  '#FFF',
textAlign: 'center',
fontFamily: 'Work Sans',
fontWeight: '600',
fontSize:'70%',

}}
>
  
  
Other Permissions</p>
                    </Col>
                    <Col xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                         
                         
                         style={{
                          display: 'flex',
                        width: '20%',
                        minHeight: '30%',
                        
                        padding: '3%',

                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius:4,
                        backgroundColor:'#256A01',
                        marginRight:'5%',
                        
                        }}>
                       <p

style={{
  color:  '#FFF',
textAlign: 'center',
fontFamily: 'Work Sans',
fontSize:'70%',

fontWeight: '600',
}}
>
  
  
Event Permission </p>
                    </Col>
                </Row>
            </Grid>








</div>






<div  >

  <div
    style={{
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      alignItems:'center',
      marginTop:'5%',
    }}
  >
    <h1
    style={{
      color:  '#292E3D',
      fontFamily: 'Work Sans',
      fontWeight: 700,
      textAlign:'center'
    }}
    >How can we help you?</h1>

<h3
    style={{
      color:  '#292E3D',
      fontFamily: 'Work Sans',
      fontWeight: 400,
      textAlign:'center',
      width:'80%',
      marginTop:20,
      marginBottom:20,
    }}
    >We are ready to work on a project of any complexity, whether it’s commercial or residential.</h3>

    <form   className="form" >

<div


>
    <div
    style={{
      flexDirection:'row',
      display:'flex',
      justifyContent:'space-around'
    }}
    >
        {/* Name */}
    <input

    style={{
      width:'40%',
      height:'3%'
    }}
    name="Name"
    placeholder="Your Name"
    >
    </input>

    
    <input
     style={{
      width:'40%',
      height:'3%'
    }}
    name="Email"
    placeholder="Email"
    >
    </input>
    </div>

    <div
  
  style={{
    flexDirection:'row',
    display:'flex',
    justifyContent:'space-around'
  }}
    >

    <input
    style={{
      marginTop:20,
      width:'40%',
      height:'3%'
    }}

    name="Phone"
    placeholder="Phone"
    >
    </input>
    <input
    style={{
      marginTop:20,
      width:'40%',
      height:'3%',
      visibility: "hidden"
    }}
    
    name="Phone"
    placeholder="Phone"
    >
      </input>
    
    </div>

    <div
    style={{
      flexDirection:'row',
      display:'flex',
      justifyContent:'center'
    }}
    >
    <input
    name="Message"
      style={{
        height:135,
        width:'80%',
      marginTop:20,

    
       }}

       placeholder="Message"
    >
    </input>
    <input
    name="Message"
      style={{
        height:135,
        width:'10%',
      marginTop:20,
        visibility:'hidden'
    
       }}

       placeholder="Message"
    >
    </input>
    </div>

    
   <div
   style={{
    display:'flex',
  alignItems:'center',
  justifyContent:'center',
   }}
   >
   <input
style={{
  borderRadius: 2,
  backgroundColor:  '#256A01',
  height:50,
  width:'80%',
  textAlign:'center',
  marginTop:50,
  marginBottom:30,
  color:'#fff',
fontFamily:'Work Sans',
fontSize: 18,
fontWeight: '600',
}}
className="button"
type="Submit"
onClick={(e)=>{Submit(e)}}

/>
   </div>
   </div>
    </form>

  </div>

  
</div>



<div
id="contact"
style={{
  display:'flex',
  justifyContent:'space-between',
  paddingLeft:'2%',
  paddingBottom:80,
  paddingTop:50,
  backgroundColor:'#F6F8F7'
}}
>
<div
style={{


}}

>
<div
style={{
  display:'flex',
  flexDirection:'row'
}}
>
<h5
  style={{
    color: '#256A01',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  }}
  >
  ADDRESS:
  </h5>
  <p
  style={{
    color: '#292E3D',
fontFamily: 'Work Sans',
fontWeight: '400',
paddingLeft:10,

  }}
  
  >122 Kuber Kartik Complex Lik Road , Andheri West</p>
</div>

<div
style={{
  display:'flex',
  flexDirection:'row',

}}
>
<h5
  style={{
    color: '#256A01',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  }}
  >
  PHONE:
  </h5>
  <p
    style={{
      color: '#292E3D',
  fontFamily: 'Work Sans',
      paddingLeft:10,
  fontWeight: '400',
    }}
  
  >+91 8691050485</p>
</div>

<div
style={{
  display:'flex',
  flexDirection:'row'
}}
>
<h5
  style={{
    color: '#256A01',
    fontFamily: 'Work Sans',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: '500',
  }}
  >
  EMAIL:
  </h5>
  <p
   style={{
    color: '#292E3D',
fontFamily: 'Work Sans',
paddingLeft:10,

fontWeight: '400',
  }}
  >etoile.liaison@gmail.com</p>
</div>
<div>
<img
        src="/bg.jpeg"
        style={{
          height:47.5,
          width:163
        }}
        />
</div>
</div>

<div>



</div>
</div>


<div
style={{
  display: 'flex',
height: 70,
paddingLeft: '10%',
alignItems: 'center',
backgroundColor: '#256A01',
}}

>
<h6
style={{
  color: '#FFF',
fontFamily: 'Work Sans',
fontWeight: '400',
}}
>ETOILE
LIAISON PVT LTD © 2023. All Rights Reserved</h6>
</div>

    </div>
  );
};

export default App;