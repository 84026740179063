import React from 'react'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function Navigation() {
  return (
    <>
      <Navbar collapseOnSelect style={{backgroundColor:'#fff', flexDirection:'row' }} fixed="top" expand="sm" >
     
        <Container style={{display: 'flex',
          }}>
             <img
          src="/bg.jpeg"
          style={{
            height:47.5,
            paddingLeft:20,
          }}
          />
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
       
          <Navbar.Collapse  id="responsive-navbar-nav">
          <Nav className="me-auto" style={{ display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',}}>
         
         <Nav.Link href="#home"
            style={{
              color:'#14171F',
              fontSize:18,
              fontWeight:400,
              marginRight:25,
            }}
            >Home</Nav.Link>
            <Nav.Link href="#about"
            style={{
              color:'#14171F',
              fontSize:18,
              fontWeight:400,
              marginRight:25,
            }}
            >About Us</Nav.Link>
            <Nav.Link href="#service"
            style={{
              color:'#14171F',
              fontSize:18,
              fontWeight:400,
              marginRight:25,
            }}
            >Services</Nav.Link>
            <Nav.Link href="#contact"
            style={{
              color:'#14171F',
              fontSize:18,
              fontWeight:400,
              marginRight:25,
            }}
            >Contact Us</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </> 
  )
}
